<template>
    <div class="box">
        <div class="box_container">
            <div style="height:10px;"></div>
            <!-- 下拉框 -->
            <div class="head" style="align-items: flex-start;">
                <div class="head_container">
                    <div class="flex" style="margin-bottom: 20px;">
                        <div class="flex head_dropDown">
                            <span>群组</span>
                            <el-select class="head_select" v-model="groupId" filterable placeholder="请选择群组">
                                <el-option v-for="item in group" :key="item.Cg_ID" :label="item.Cg_Name"
                                    :value="item.Cg_ID">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="flex head_dropDown">
                            <span>发送人</span>
                            <el-select class="head_select" v-model="sos.fsUser" filterable placeholder="请选择用户">
                                <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name"
                                    :value="item.User_ID">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="flex head_dropDown">
                            <span>发送时间</span>
                            <el-date-picker v-model="sos.fsStartTime" type="datetimerange" range-separator="~"
                                :picker-options="pickerOptions" start-placeholder="开始范围" end-placeholder="结束范围">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex head_dropDown">
                            <span>状态</span>
                            <el-select class="head_select" v-model="state" filterable placeholder="请选择状态">
                                <el-option v-for="item in stateData" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="flex head_dropDown">
                            <span>处理人</span>
                            <el-select class="head_select" v-model="sos.clUser" filterable placeholder="请选择用户">
                                <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name"
                                    :value="item.User_ID">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="flex head_dropDown">
                            <span>处理时间</span>
                            <el-date-picker v-model="sos.clStartTime" type="datetimerange" range-separator="~"
                                :picker-options="pickerOptions" start-placeholder="开始范围" end-placeholder="结束范围">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="head_search head_dropDown mouse" @click="search()">
                        搜索
                    </div>
                </div>
            </div>
            <!-- 下拉结束 -->
            <!-- 表格 -->
            <div class="table table_tow">
                <history-table :bool="bool" :tableData="tableData" :tableHead="tableHead" ref="history">
                    <el-table-column slot="operation" label="操作" width="285">
                        <template slot-scope="scope">
                            <div class="table_box">
                                <span class="table_but mouse" @click="seeOssPosition(scope.row)">查看sos位置</span>
                            </div>
                        </template>
                    </el-table-column>
                </history-table>
            </div>
            <!-- 分页 -->
            <div class="pagination">
                <div class="tip-sos">临时群组中的SOS告警不做记录</div>
                <el-pagination class="pagination_bottom" :current-page="paging.pageNo" background
                    :page-sizes="[20, 40, 60, 80]" layout="total,prev, pager, next,jumper,slot, sizes"
                    @size-change="sizeChange" @prev-click="prevClick" @next-click="nextClick"
                    @current-change="currentChange" :total="paging.total">
                    <span class="confirbtn" @click="comfir()">确定</span>
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script src=""></script>
<script>
import {querySOSRecord,queryGroupName,queryUserName} from "@/administration/history.js";
import historyTable from '@/components/history/table.vue';
export default {
  components: { 
    historyTable   
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    let curDate = (new Date()).getTime();
                    let three = 90 * 24 * 3600 * 1000;
                    let threeMonths = curDate - three;
                    return time.getTime() > Date.now() || time.getTime() < threeMonths;
                }
            },
            valueStart:'',  //开始时间
            group:[     //群组
            ],
            groupId:'',   //群组id
            user:[     //用户
            ],
            userId:'',   //用户id
            stateData:[     //状态
                {
                    value:-1,
                    label:'请选择状态'
                },
                {
                    value:0,
                    label:'未处理'
                },
                {
                    value:2,
                    label:'已处理'
                }
            ],
            state:null,
            radiobroadcastData:[    //广播类型
                {
                    value:-1,
                    label:'请选择类型'
                },
                {
                    value:1,
                    label:'文本'
                },
                {
                    value:3,
                    label:'语音'
                }
            ],
            radiobroadcastType:'',
            currentPage4: 4,
            paging:{
                total:0,
                currentPage:1,
                pageNo:1,
                pageSize:20
            },
            sos:{
                clUser:'',  //发送人
                fsUser:'',  //处理人
                fsStartTime:'', //发送开始时间
                clStartTime:'' //处理结束时间  
            },
            // sos
            userInfo:{}, //用户信息
            chooselist: [],     //选中数据
            tableData:[],    //table数据
            tableHead:[
                    {     
                            lable:'发送人',
                            prop:'sendName'
                            },
                            {     
                            lable:'群组',
                            prop:'Cg_Name'
                            },
                            {     
                            lable:'发送时间',
                            prop:'sos_start_time'
                            },
                            {    
                            lable:'处理人',
                            prop:'operatorName'
                            },
                            {    
                            lable:'处理时间',
                            prop:'sos_end_time'
                            },
                            {    
                            lable:'状态',
                            prop:'sos_status'
                            }
            ],       //表头数据
            bool:true,
            historyIndex:0,
            music:null,
            showcontent: false,
            tipstext: '确定下载所选的录音吗？',
            ossMap:{},
            excelData:{
                ly:[],
                wb:[]
            },
            json_fields:{},
            downloadName:'记录'
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryGroupName();
        this.getQueryUserName();
        this.getQuerySOSRecord();
    },
    beforeCreate: function () { },
    mounted() { },
    methods: {
        seeOssPosition(row){   //查看oss
            // console.log(row)
            this.ossMap = {
                sos_location_at:JSON.parse(row.sos_location_at),
                sendname:row.sendName,
                time:row.sos_start_time,
                cg_name:row.Cg_Name,
                sos_msg_id:row.sos_msg_id,
                sosStatus:row.sos_status
            }
            this.$router.push(`/history/sosPosition?ossMap=${JSON.stringify(this.ossMap)}`);
        },
        getQuerySOSRecord(){   //sos记录
            let that = this;
            let sos = that.sos;
            let data = {
                uId:that.userInfo.id,
                pageNo:that.paging.pageNo,
                pageSize:that.paging.pageSize
            }
            if( sos.fsStartTime !='' && sos.fsStartTime != null ){  //发送时间
                data.sendStartDate = this.$moment(sos.fsStartTime[0]).format("YYYY-MM-DD HH:mm:ss");
                data.sendEndDate = this.$moment(sos.fsStartTime[1]).format("YYYY-MM-DD HH:mm:ss");
            } 
            if(sos.clStartTime != '' && sos.clStartTime != null){ //处理时间
                data.endStartDate = this.$moment(sos.clStartTime[0]).format("YYYY-MM-DD HH:mm:ss");
                data.endEndDate = this.$moment(sos.clStartTime[1]).format("YYYY-MM-DD HH:mm:ss");
            }
            if(sos.clUser != ''){   //操作人
                data.operator=sos.clUser
            }
            if(sos.fsUser != ''){ //发送人
                data.sendId=sos.fsUser
            }
            if(that.groupId != '' && that.groupId > 0 ){
                data.gId=that.groupId   //群组id
            }
            if(that.state != null){     //状态 0 待处理 2 已处理
                data.status=that.state
            }
            querySOSRecord(data).then(res =>{
                   if(res.data.code == 200){
                             that.paging.total = res.data.data.count;
                             let list = res.data.data.list;
                             if(list.length > 0){
                            list.forEach(item => {
                                item.sos_start_time = this.$moment(item.sos_start_time).format("YYYY-MM-DD HH:mm:ss");
                                item.sos_end_time = this.$moment(item.sos_end_time).format("YYYY-MM-DD HH:mm:ss");
                            });
                            that.tableData = list;
                            }else{
                                that.tableData = [];
                            }
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        getQueryGroupName(){  //查询群组下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryGroupName(data).then(res =>{
                    if(res.data.code == 200){
                        let list = res.data.data;
                        list.forEach(ele => {
                            if (ele.isactive == 1) {
                            } else {
                                ele.Cg_Name = ele.Cg_Name + "(已删除)"
                            }
                        })
                         list.unshift({
                                Cg_ID:-1,
                                Cg_Name:'请选择群组'
                        })
                        this.group = list;
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        getQueryUserName(){ //获取用户下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryUserName(data).then(res=>{
                   if(res.data.code == 200){
                        that.user = res.data.data;
                        that.user.unshift({
                            User_ID:-1,
                            User_Name:'请选择用户'
                        })
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        search(){      //搜索
            this.paging.pageNo = 1;
            this.refresh();
        },
         closepop() {
            this.showcontent = false
        },
        refresh(){      //刷新
                this.getQuerySOSRecord();
        },
        comfir() {  //确定按钮
                this.refresh();
            },
        sizeChange(pageSize) {
                //当前页数据发生改变时
                this.paging.pageSize = pageSize;
                this.refresh();
            },
            prevClick(pageNum) {
                //前进按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            nextClick(pageNum) {
                //退后按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            currentChange(pageNum) {
                //当前页发生改变时
                this.paging.pageNo = pageNum;
                this.refresh();
            }
    },
    watch: {
        //监听事件
        //监听事件
        groupId(val) {
            // console.log(val == -1)
            if (val == -1) {
                this.groupId = '';
            }
        },
        'sos.fsUser'(val){
            if (val == -1) {
                this.sos.fsUser = '';
            }
        },
        'sos.clUser'(val){
            if (val == -1) {
                this.sos.clUser = '';
            }
        },
        state(val){
            if(val == -1){
                this.state = null
            }
        }
    },
};
</script>

<style scoped lang="less">
.box {
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #ffffff;

    &_container {
        width: calc(100% - 20px);
        height: 100%;
        margin: auto;

        .head {
            width: 100%;
            padding: 10px 0px;
            background-color: #f0f0f0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 15px;
            color: #5a5655;

            &_container {
                flex: 1;
                margin-left: 10%;
            }

            &_dropDown {
                font-size: 15px;
                color: #5a5655;
                margin-right: 20px;

                span {
                    margin-right: 5px;
                }
            }

            &_select {
                width: 160px;
                height: 40px;
            }

            &_picker {
                width: 280px !important;
                text-align: center;
            }

            &_search {
                width: 70px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #4d4d4d;
                background-size: 100% 100%;
                border-radius: 5px;
                color: #ffffff !important;
            }

            &_but {
                width: 110px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #327677;
                border-radius: 3px;
                color: #ffffff !important;
                margin-right: 5px;
            }
        }

        .table_tow {
            height: calc(100vh - 73px - 235px);
        }

        .table {
            width: 100%;
            background-color: #f0f0f0;
            overflow: auto;
            color: #000000;

            &_box {
                width: 100%;
                text-align: center;
            }

            &_but {
                color: #67c5cd;
                margin-right: 10px;
            }
        }

        .pagination {
            width: 100%;
            // height: 80px;
            text-align: left;
            position: relative;
            background-color: #ffffff;

            /deep/.el-pagination__jump {
                margin-left: 0;
            }

            .tip-sos {
                text-align: right;
                color: #b2b2b2;
                height: 30px;
                line-height: 30px;
            }

            &_bottom {
                padding: 15px 0px;
                // position: absolute;
                // bottom: 0px;
                // left: 0px;
            }

            .confirbtn {
                background-color: #5a5657;
                padding: 0 6px;
                color: #ffffff;
                margin-left: 4px;
                cursor: pointer;
                border-radius: 2px;
                // line-height: 14px;
            }
        }
    }
}

.video-box {
    width: 100%;
    height: 480px;
}

vidio {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bg_one {
    color: #ffffff;
    background: url("../../assets/history/select.png");
}

.bg_tow {
    color: #000000;
    background: url("../../assets/history/select1.png");
}

.flex {
    display: flex;
    align-items: center;
}
</style>